import * as React from "react"
import appInsights from '../../../../AppInsights';
import Sostenibilidad from "../../../route/sustainability_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Sostenibilidad props={props} />
      </React.Fragment>
  )
}

export default IndexPage
